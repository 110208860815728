<template>
  <div>
    <v-dialog v-model="detailDialog" max-width="700" persistent>
      <v-card>
        <v-card-title>Details</v-card-title>
        <v-card-text>
          <v-card outlined>
            <v-card-subtitle style="padding: 7px 16px 0px">
              Initial Complaints
            </v-card-subtitle>
            <v-card-text> {{ details.initial_complaints }}</v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text>
              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">provider</div>
                    <div class="myright">
                      {{ details.subscriberName | capitalize }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">meeting location</div>
                    <div class="myright">{{ details.meeting_location }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">start</div>
                    <div class="myright">{{ details.time_start | myTime }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">end</div>
                    <div class="myright">{{ details.time_end | myTime }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">accepted</div>
                    <div class="myright">
                      {{ details.accepted ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">more information</div>
                    <div class="myright">
                      {{ details.more_information ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">cancelled</div>
                    <div class="myright">
                      {{ details.cancelled ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">done</div>
                    <div class="myright">{{ details.done ? "Yes" : "No" }}</div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";

export default {
  props: {
    detailDialog: Boolean,
    myId: {type: Number, default: 0},
  },
  data: () => ({details: {}}),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
          .appointDetails(this.myId)
          .then((response) => {
            console.log(response.data);
            this.details = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeDetail", payload);
    },
  },
};
</script>
