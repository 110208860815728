<template>
  <v-dialog v-model="updateDialog" max-width="700" persistent>
    <v-card>
      <v-card-title>edit request</v-card-title>
      <v-card-text>
        <v-textarea
            v-model="create.initialComplaints"
            label="initial complaints"
            outlined
            rows="4"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.create.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {updateDialog: Boolean, myId: {type: Number}},
  data: () => ({
    startmenu: false,
    endmenu: false,
    meetypesList: [],
    create: {
      initialComplaints: null,
    },
  }),
  validations: {
    create: {
      initialComplaints: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      Restful.medics.patient.request
          .find(this.myId)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.create.initialComplaints = response.data.initial_complaints;
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeUpdate", payload);
    },
    submit() {
      let initialComplaints = this.create.initialComplaints;
      let data = {
        initialComplaints,
      };
      console.log(data);
      let payload = {id: this.myId, state: false, data: data};
      this.$emit("submitUpdate", payload);
    },
  },
};
</script>
